<template>
  <div class="auth-wrapper auth-v1 px-2 mainbg">
    <div class="auth-inner py-2 iner">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            src="ma5.png"
            alt="logo"
            height="85"
          >
        </b-link>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{ invalid }"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group>
              <label for="login-password">เบอร์มือถือ</label>
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="เบอร์มือถือ"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">รหัสผ่าน</label>
                <b-link :to="{ name: 'auth-forgot' }">
                  <small>ลืมรหัสผ่าน?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                จดจำรหัสผ่าน
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="gradient-primary"
              block
              :disabled="invalid"
            >
              เข้าสู่ระบบ
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>หากยังไม่มีบัญชีผู้ใช้? </span>
          <b-link :to="{ name: 'auth-register' }">
            <span>สร้างบัญชีผู้ใช้</span>
          </b-link>
        </b-card-text>

        <!-- <div class="divider my-2">
          <div class="divider-text">
            หรือ
          </div>
        </div> -->

        <!-- social button -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      Ip: null,
      LottoList: [
        { text: 'กรุณาเลือกหวย', value: null, SubList: null },
        { text: 'หวยรัฐบาล', value: 'THG', SubList: null },
        {
          text: 'ธกส',
          value: 'BAAC',
          SubList: [
            { text: 'หวย ธกส.(BAAC1)', value: 'BAAC1' },
            { text: 'หวย ธกส.(BAAC2)', value: 'BAAC2' },
          ],
        },
      ],
      // sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.GetIp()
  },
  methods: {
    async GetIp() {
      try {
        const { data: res } = await axios.get('https://api.ipify.org')
        this.Ip = res
      } catch (e) {
        this.Ip = null
      }
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              Detail: {
                Ip: this.Ip,
                UserAgent: navigator.userAgent,
                Platform: navigator.platform,
              },
            })
            .then(response => {
              const userData = response.data
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              localStorage.setItem('LottoList', JSON.stringify(this.LottoList))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit(
              //   'app-ecommerce/UPDATE_CART_ITEMS_COUNT',
              //   userData.extras.eCommerceCartItemsCount,
              // )

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `มหาล๊อตโต ยินดีต้อนครับค่ะ  คุณ${
                        `${userData.fname} ${userData.sname}`
                        || userData.username
                      }`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: '',
                    },
                  })
                })
            })
            .catch(error => {
              this.$bvToast.toast('เบอร์โทรหรือรหัสที่คุณป้อนไม่ถูกต้อง', {
                title: 'ล็อกอินไม่สำเร็จ',
                variant: 'danger',
                solid: true,
              })
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style scoped>
.iner {
  position: relative;
  z-index: 2;
}
.mainbg {
  background: rgb(58, 8, 8);
  background: linear-gradient(
    158deg,
    rgb(21, 31, 20) 0%,
    rgb(224, 218, 218) 48%,
    rgb(31, 118, 5) 96%
  );
  width: 100%;
  min-height: 100vh;
  left: 0;
  position: relative;
  z-index: 1;
  display: flex;
  text-align: inherit;
}
.mainbg:after,
.mainbg:before {
  content: '';
  width: 100%;
  position: absolute;
  z-index: 0;
}
.mainbg:before {
  background: url(/gradiant-top.svg) top no-repeat;
  background-size: 100% auto;
  min-height: 100vh;
  height: auto;
}
.mainbg:after {
  background: url(/gradiant-bottom.svg) bottom no-repeat;
  background-size: 60% auto;
  bottom: 0;
  height: 100vh;
}
label {
  font-size: 1.149rem;
}
.btn-facebook {
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgb(0, 102, 255);
}

.btn-line {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 0;
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgb(81, 255, 0);
}

.btn-google {
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  background-color: rgb(255, 0, 0);
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
